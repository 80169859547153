import { IGatsbyImageData } from "gatsby-plugin-image"
import React from "react"
import tw from "twin.macro"

interface Props {
  videos: Array<{
    video: {
      mediaItemUrl: string
    }
    posterImage: {
      localFile: {
        publicURL: string
      }
    }
  }>
}

const EconomicVideos: React.FC<Props> = ({ videos }) => {
  return (
    <article css={[tw`max-w-3xl mx-auto flex flex-col gap-16 mt-16`]}>
      {videos?.map(({ video, posterImage }, index) => (
        <video
          preload="metadata"
          controls
          controlsList="nodownload"
          css={[tw`md:rounded-xl`]}
          poster={posterImage?.localFile?.publicURL}
        >
          <source src={video?.mediaItemUrl} />
        </video>
      ))}
    </article>
  )
}

export default EconomicVideos
