import React, { useState, useContext, useEffect } from "react"
import tw from "twin.macro"
import TranslateClient from "../../clients/TranslateClient"
import { getStringValuesArray, mapValuesToObject } from "../../helpers"
import { LanguageContext } from "../../context/Language"

interface Props {
  title: string
}

const EconomicImpactTitle: React.FC<Props> = ({ title }) => {
  const [text, setText] = useState({ title })
  const { language } = useContext(LanguageContext)

  useEffect(() => {
    if (language === "en") {
      setText({ title })
    } else {
      ;(async () => {
        const textToTranslate = getStringValuesArray([title])
        const translations = await TranslateClient.translate(
          textToTranslate,
          language
        )
        const translated = mapValuesToObject(text, translations)
        setText(translated)
      })()
    }
  }, [language])
  return (
    <article
      css={[
        tw`px-8 md:px-0 pt-20 text-center bg-gradient-to-b from-[#96e3ff38] to-white`,
      ]}
    >
      <h1
        css={[tw`text-6xl font-extrabold text-blue-250`]}
        dangerouslySetInnerHTML={{ __html: text?.title }}
      />
    </article>
  )
}

export default EconomicImpactTitle
