import { GatsbyImage, getImage } from "gatsby-plugin-image"
import React, { useState, useContext, useEffect } from "react"
import tw from "twin.macro"
import TranslateClient from "../../clients/TranslateClient"
import { getStringValuesArray, mapValuesToObject } from "../../helpers"
import { LanguageContext } from "../../context/Language"
import { ImageSharp } from "../../templates/templates"
import { Image } from "../Image"

interface Props {
  reasonsForFlyingLocal: Array<{
    title: string
    image: ImageSharp
  }>
}

const EconomicReasons: React.FC<Props> = props => {
  return (
    <article
      css={[
        tw`max-w-4xl flex flex-col md:flex-row items-center md:justify-between md:items-start gap-8 md:gap-0 mt-16 mx-auto`,
      ]}
    >
      {props.reasonsForFlyingLocal?.map((item, index) => (
        <ReasonItem key={index} {...item} />
      ))}
    </article>
  )
}

export default EconomicReasons

interface ReasonProp {
  title: string
  image: ImageSharp
}

const ReasonItem: React.FC<ReasonProp> = ({ title, image }) => {
  const gatsbyImage = getImage(
    image?.localFile?.childImageSharp?.gatsbyImageData
  )

  const [text, setText] = useState({ title })
  const { language } = useContext(LanguageContext)

  useEffect(() => {
    if (language === "en") {
      setText({ title })
    } else {
      ;(async () => {
        const textToTranslate = getStringValuesArray([title])
        const translations = await TranslateClient.translate(
          textToTranslate,
          language
        )
        const translated = mapValuesToObject(text, translations)
        setText(translated)
      })()
    }
  }, [language])

  return (
    <div css={[tw`flex flex-col gap-4 items-center max-w-[17rem]`]}>
      <div css={[tw`relative rounded-full h-60 w-60`]}>
        <Image
          css={[tw`rounded-full h-60 w-60 bg-blue-300 opacity-50 z-10`]}
          src={gatsbyImage}
          publicURL={image?.localFile?.publicURL}
          alt=""
          role="presentation"
        />
        <div
          css={[
            tw`absolute top-0 left-0 w-full h-full bg-blue-250 rounded-full`,
          ]}
        />
      </div>
      <p
        css={[tw`text-3xl text-blue-250 font-extrabold text-center`]}
        dangerouslySetInnerHTML={{ __html: text?.title }}
      />
    </div>
  )
}
