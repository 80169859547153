import { graphql } from "gatsby"
import { GatsbyImage, getImage } from "gatsby-plugin-image"
import React from "react"
import EconomicImpactTitle from "../../components/HowFlyingEconomicImpact/EconomicImpactTitle"
import tw from "twin.macro"
import { TemplateProps } from "../templates"
import EconomicReasons from "../../components/HowFlyingEconomicImpact/EconomicReasons"
import EconomicVideos from "../../components/HowFlyingEconomicImpact/EconomicVideos"
import EconomicImpactDetails from "../../components/HowFlyingEconomicImpact/EconomicImpactDetails"
import { HowFlyingHelpsTemplatePageData } from "../how-flying-local-helps"
import { SEO } from "../../components/SEO"
import { Image } from "../../components/Image"

const EconomicImpact: React.FC<
  TemplateProps<HowFlyingHelpsTemplatePageData>
> = ({ data, location }) => {
  const { title, featuredImage, template } = data.wpPage
  const { economicImpactSection, reasonsForFlyingLocal, videos } =
    template.howFlyingHelpsPage
  const image = getImage(
    featuredImage?.node?.localFile?.childImageSharp?.gatsbyImageData
  )

  return (
    <div className="page-content">
      <SEO url={location.href} title={title} />
      <section>
        <Image
          src={image}
          publicURL={featuredImage?.node?.localFile?.publicURL}
          role="presentation"
          css={[tw`h-80`]}
          objectPosition="100% 90%"
          alt=""
        />
        <EconomicImpactTitle title={title} />
        <EconomicReasons reasonsForFlyingLocal={reasonsForFlyingLocal} />
        <EconomicVideos videos={videos} />
        <EconomicImpactDetails {...economicImpactSection} />
      </section>
    </div>
  )
}

export const query = graphql`
  query ($id: String) {
    wpPage(id: { eq: $id }) {
      content
      title
      featuredImage {
        node {
          localFile {
            publicURL
            childImageSharp {
              gatsbyImageData
            }
          }
        }
      }
      template {
        ... on WpTemplate_HowFlyingLocalHelps {
          howFlyingHelpsPage {
            economicImpactSection {
              crown
              headline
              link {
                target
                title
                url
              }
            }
            reasonsForFlyingLocal {
              fieldGroupName
              title
              image {
                localFile {
                  publicURL
                  childImageSharp {
                    gatsbyImageData
                  }
                }
              }
            }
            videos {
              video {
                mediaItemUrl
              }
              posterImage {
                localFile {
                  publicURL
                }
              }
            }
          }
        }
      }
    }
  }
`

export default EconomicImpact
